import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './forgot-password.component.html',
  styles: [`
  em { float: right; color: #E05C65; padding-left: 10px; }
  `]
})
export class ForgotPasswordComponent implements OnInit  {

  email:string
  code: string
  name: string
  user_id: string
  show_type_email: boolean
  show_validate_code: boolean
  show_choose_password: boolean
  show_success: boolean


  constructor (private auth: AuthService, private router: Router) {

  }

  ngOnInit() {
    this.show_type_email = true;
    this.show_validate_code = false;
    this.show_choose_password = false;
  }

  doTypeEmail(forgotPasswordForm) {
    //console.log(formValues.txtEmail);
    this.auth.forgotPasswordRequest(forgotPasswordForm.value.txtEmail).subscribe((res) => {

      if(res) {
        this.email = forgotPasswordForm.value.txtEmail;
        this.show_type_email = false;
        this.show_validate_code = true;
        this.show_choose_password = false;
        this.show_success = false;
      }

    }, err => {
      //console.log('Err Aqui');
      if (err instanceof HttpErrorResponse) {
        const errorMessages = new Array<{ propName: string; errors: string }>();

        if (err.status === 401) {
          forgotPasswordForm.controls.txtEmail.setErrors({serverError : {'incorrect': true}})
          console.log(err.message);
        }
      }
    })
  }

  doValidateCode(forgotPasswordCodeForm) {
    //console.log(formValues.txtEmail);
    this.auth.forgotPasswordValidateCode(forgotPasswordCodeForm.value.txtCode).subscribe((res) => {

      if(res) {
        this.show_type_email = false;
        this.show_validate_code = false;
        this.show_choose_password = true;
        this.show_success = false;

        this.code = forgotPasswordCodeForm.value.txtCode;
        this.name = res.name;
        this.user_id = res._id;
        this.email = res.email;
      }

    }, err => {
      //console.log('Err Aqui');
      if (err instanceof HttpErrorResponse) {
        const errorMessages = new Array<{ propName: string; errors: string }>();

        if (err.status === 400) {
          forgotPasswordCodeForm.controls.txtCode.setErrors({serverError : {'incorrect': true}})
          console.log(err.message);
        }
      }
    })
  }

  doChoosePassword(forgotPasswordChooseForm) {
    //console.log(formValues.txtEmail);

    if(forgotPasswordChooseForm.value.txtNewPassword != forgotPasswordChooseForm.value.txtConfirmPassword) {
      forgotPasswordChooseForm.controls.txtConfirmPassword.setErrors({mismatch : {'incorrect': true}})
      return
    }

    this.auth.forgotPasswordChoosePassword(this.code, this.user_id, forgotPasswordChooseForm.value.txtNewPassword).subscribe((res) => {

      if(res) {
        this.show_validate_code = false;
        this.show_type_email = false;
        this.show_choose_password = false;
        this.show_success = true;
      }

    }, err => {
      //console.log('Err Aqui');
      if (err instanceof HttpErrorResponse) {
        const errorMessages = new Array<{ propName: string; errors: string }>();

        if (err.status === 400) {
          forgotPasswordChooseForm.controls.txtConfirm.setErrors({serverError : {'incorrect': true}})
          console.log(err.message);
        }
      }
    })
  }

  goBackToLogin() {
    this.router.navigate(['/']);
  }

}
