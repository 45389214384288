import { CustomerComponent } from './account/customer.component';


import { Routes } from '@angular/router'
import { LoginComponent } from './account/login.component';
import { LogoutComponent } from './account/logout.component';
import { ForgotPasswordComponent } from './account/forgot-password.component';
import { HomeComponent } from './home.component';
import { Error404Component } from './shared/404.component';
import { EditProfileComponent } from './account/edit-profile.component';
import { ChangePasswordComponent } from './account/change-password.component';

export const appRoutes: Routes = [

  { path: '', component: LoginComponent},
  { path: 'home', component: HomeComponent},
  { path: 'login', component: LoginComponent},
  { path: 'edit-profile', component: EditProfileComponent},
  { path: 'signup', component: CustomerComponent},
  { path: 'change-password', component: ChangePasswordComponent},
  { path: 'login/forgot-password', component: ForgotPasswordComponent},
  { path: 'logout', component: LogoutComponent},
  { path: '404', component: Error404Component},
  { path: 'verbos', loadChildren:() => import('./verbos/verbos.module').then(m => m.VerbosModule)},
  { path: 'temas', loadChildren:() => import('./temas/temas.module').then(m => m.TemasModule)}

]
