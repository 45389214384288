

export interface IUser {
  _id: string,
  name: string
  token: string
  email: string

}

export class User implements IUser {

  constructor(){}

  _id: string
  name: string
  token: string
  email: string


}
