<div class="container">
  <h2>Spanish App</h2>
  <hr>
  <div *ngIf="show_type_email && (!show_validate_code && !show_choose_password && !show_success)">
    <p>If you can't remember your current password, we need to create a new one.
      Please type your e-mail address below and you will get an email with a code.</p>
    <div class="col-md-4">
      <form #forgotPasswordForm="ngForm" (ngSubmit)="doTypeEmail(forgotPasswordForm)" autocomplete="off" novalidate>
        <div class="form-group">
            <label for="txtEmail">E-mail:</label>
            <em *ngIf="forgotPasswordForm.controls.txtEmail?.errors?.required">Required</em>
            <em *ngIf="forgotPasswordForm.controls.txtEmail?.errors?.pattern">Invalid Email</em>
            <em *ngIf="forgotPasswordForm.controls.txtEmail?.errors?.serverError">Email does not exist</em>
            <input (ngModel)="email" name="txtEmail" id="txtEmail" type="text" class="form-control" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" >
        </div>
        <div class="form-group">
          <button type="submit" [disabled]="forgotPasswordForm.invalid" class="btn btn-primary">Request New Password</button>
        </div>
      </form>
    </div>
  </div>
  <!--   -->
  <div *ngIf="show_validate_code && (!show_type_email && !show_choose_password && !show_success)">
    <p>Please type the code you got by email:</p>
    <div class="col-md-4">
      <form #forgotPasswordCodeForm="ngForm" (ngSubmit)="doValidateCode(forgotPasswordCodeForm)" autocomplete="off" novalidate>
        <div class="form-group">
          <label>Email: </label><br>
          <span><b>{{email}}</b></span>
      </div>
        <div class="form-group">
            <label for="txtCode">Code:</label>
            <em *ngIf="forgotPasswordCodeForm.controls.txtCode?.errors?.serverError">Code is invalid</em>
            <input (ngModel)="code" name="txtCode" id="txtCode" type="text" class="form-control" required style="width: 80px;" maxlength="6">
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Validate Code</button>
          <br><br><br><br><br><br><br><br>
          <button type="submit" class="btn btn-primary">Resend Code</button>
        </div>
      </form>
    </div>
  </div>
  <!--   -->
  <div *ngIf="show_choose_password && (!show_type_email && !show_validate_code && !show_success)">
    <p>Choose your new password:</p>
    <div class="col-md-4">
      <form #forgotPasswordChooseForm="ngForm" (ngSubmit)="doChoosePassword(forgotPasswordChooseForm)" autocomplete="off" novalidate>
      <div class="form-group">
        <label>Email: </label><br>
        <span><b>{{email}}</b></span>
    </div>
        <div class="form-group">
            <label for="txtNewPassword">New Password:</label>&nbsp;
            <em *ngIf="forgotPasswordChooseForm.controls.txtNewPassword?.invalid && (forgotPasswordChooseForm.controls.txtNewPassword?.touched)">Required</em>
            <input (ngModel)="code" name="txtNewPassword" id="txtNewPassword" type="password" class="form-control" required >
        </div>
        <div class="form-group">
          <label for="txtConfirmPassword">Confirm Password:</label>&nbsp;
          <em *ngIf="forgotPasswordChooseForm.controls.txtConfirmPassword?.errors?.required">Required</em>
          <em *ngIf="forgotPasswordChooseForm.controls.txtConfirmPassword?.errors?.mismatch">Passwords do not match</em>
          <input (ngModel)="code" name="txtConfirmPassword" id="txtConfirmPassword" type="password" class="form-control" required >
      </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Save New Password</button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="show_success && (!show_type_email && !show_validate_code && !show_choose_password)">
    <h2>Success!</h2>
    <div class="col-md-4">
      <p>Your password has been changed.</p>
      <br>
      <button type="button" class="btn btn-primary" (click)="goBackToLogin()">Go to login page</button>
    </div>
  </div>
</div>
