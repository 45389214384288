import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/account/auth.service";
import { User } from "./auth.model";

@Component({
  templateUrl: './edit-profile.component.html',
  styles: [`
    em {float:right; color:#E05C65; padding-left: 10px}
    .error input { background-color: #E05C65; }
    .error ::-webkit-input-placeholder { color: #999; }
    .error ::-moz-placeholder { color: #999; }
    .error :-moz-placeholder { color: #999; }
    .error :ms-input-placeholder { color: #999; }
  `]
})
export class EditProfileComponent implements OnInit {

  constructor(private auth:AuthService,
     private router:Router,
     private route: ActivatedRoute,
     private fb: FormBuilder){

  }

  email:string;
  name:string;
  profileForm: FormGroup;
  showChangePassword: boolean;

  ngOnInit(){

      this.profileForm = this.fb.group({
        txtName: '',
        txtEmail: ''
      });

  }

  cancel() {
    this.router.navigate(['home'])
  }

  saveProfile(formValues) {
    if(this.profileForm.valid) {

      let objUser = new User();

      objUser._id = this.auth.currentUser._id;
      objUser.name = formValues.txtName;
      objUser.email = formValues.txtEmail;


      //go to service and save it


    }
  }

  toggleChangePassword() {
    this.router.navigate(['change-password'])
  }


  getErrorMessage_txtName() { return  "Required" }
  getErrorMessage_txtEmail_Required() { return "Required" }
  getErrorMessage_txtEmail_Invalid() { return "Invalid" }
  getErrorMessage_txtEmail_Exists() { return "Already exists" }

}
