import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";


@Component({
  template: `<h1>Logout!</h1>`
})
export class LogoutComponent {

  constructor(private auth:AuthService, private router:Router) {

    auth.logout();
    this.router.navigate([''])

  }
}
