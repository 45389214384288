import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { appRoutes } from './routes';
import { AuthService } from './account/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageService } from './shared/local.storage.service';
import { LoginComponent } from './account/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditProfileComponent } from './account/edit-profile.component';
import { ChangePasswordComponent } from './account/change-password.component';
import { ForgotPasswordComponent } from './account/forgot-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalComponent } from './shared/modal/modal.component';
import { ToolTipComponent } from './shared/tooltip/tooltip.component';
import { NavBarPrimaryComponent } from './shared/navbar-primary/navbar-primary.component';
import { NavBarSecondaryComponent } from './shared/navbar-secondary/navbar-secondary.component';
import { AccordionComponent } from './shared/content/accordion/accordion.component';
import { MessageComponent } from './shared/content/message/message.component';
import { TabsComponent } from './shared/content/tabs/tabs.component';
import { ThumbnailListComponent } from './shared/content/thumbnail-list/thumbnail-list.component';
import { PillsComponent } from './shared/pills/pills.component';
import { TabsPaneComponent } from './shared/content/tabs/tabs-pane/tabs-pane.component';
import { AccordionPaneComponent } from './shared/content/accordion/accordion-pane/accordion-pane.component';
import { CustomerComponent } from './account/customer.component';

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ModalComponent,
    ToolTipComponent,
    NavBarPrimaryComponent,
    NavBarSecondaryComponent,
    AccordionComponent,
    MessageComponent,
    TabsComponent,
    ThumbnailListComponent,
    PillsComponent,
    TabsPaneComponent,
    AccordionPaneComponent,
    CustomerComponent
  ],
  providers: [
    AuthService,
    LocalStorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
