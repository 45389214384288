<div class="container">
  <h1>Edit Profile</h1>
  <hr>

  <div class="col-md-4">
    <div class="form-group">
      <label *ngIf="!showChangePassword" for="btnChangePassword">Password:</label>
      <button *ngIf="!showChangePassword" type="button" class="btn btn-primary" (click)="toggleChangePassword()" id="btnChangePassword">Change</button>
    </div>
  </div>
  <div class="col-md-4" *ngIf="(!showChangePassword)">
    <form [formGroup]="profileForm" autocomplete="off" novalidate>
      <div class="field-group">
        <div class="content-input-field">
          <input [formControlName]="txtName" name="txtName" id="txtName" type="text" required placeholder="Name" />
        </div>
      </div>
      <div class="field-group">
        <div class="content-input-field">
          <input [formControlName]="txtEmail" name="txtEmail" id="txtEmail" type="text" required placeholder="Email" />
        </div>
      </div>

      <button type="submit" [disabled]="profileForm.invalid"  class="btn btn-primary">Save</button>
      <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
    </form>
  </div>
</div>
