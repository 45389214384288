import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";


@Component({
  templateUrl: './login.component.html',
  styleUrls: ["./login.component.css"]
})
export class LoginComponent {

  email:string;
  password: string;
  mouseoverlogin
  loginInvalid: boolean = false
  backend_url: string;

  constructor(private auth:AuthService, private router:Router) {

    this.backend_url = environment.backend_url;

    console.log('LOGIN COMPONENT (IS AUTHENTICATED)='+auth.isAuthenticated())
    if(auth.isAuthenticated())
      router.navigate(['home']);

  }

  login(formValues) {
    console.log(formValues.txtPassword);
    this.auth.loginUser(formValues.txtEmail, formValues.txtPassword).subscribe(resp => {

      if(!resp) {
        this.loginInvalid = true;
      } else {

        this.loginInvalid = false;
        this.router.navigate(['home']);

      }
    })
  }

  logout() {

    this.auth.logout()
  }

  cancel() {
    this.router.navigate([''])
  }
}
