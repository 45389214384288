import { Component } from '@angular/core';
import { AuthService } from './account/auth.service';

@Component({
  selector: 'saa-app',
  template: `<header class="header">
  <div class="header__content">
      <h1
          class="header__title">
          Spanish App
      </h1>
      <span class="header__sub-title">For Modern Angular Applications</span>
  </div>
  <div *ngIf="auth.isAuthenticated()">
    <saa-navbar-primary></saa-navbar-primary>
  </div>
</header>
<router-outlet></router-outlet>
<footer class="footer">
  <saa-navbar-secondary></saa-navbar-secondary>
  <div class="footer__content">
      <span class="footer__title">Styling Angular Applications</span>
      <span class="footer__sub-title">For Modern Angular Applications</span>
  </div>
</footer>
`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  pills = [
    {
      label: 'HTML'
    },
    {
      label: 'CSS'
    },
    {
      label: 'SASS'
    }
];

  constructor(public auth: AuthService) {

  }

}
