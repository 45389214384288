import { Component, OnInit } from "@angular/core";
import { AuthService } from "./account/auth.service";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  name: string;
  email: string;

  pills = [
    {
      label: 'HTML'
    },
    {
      label: 'CSS'
    },
    {
      label: 'SASS'
    }
];

  constructor(private auth: AuthService) {

  }

  ngOnInit(){

  }
}
