import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/account/auth.service";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit  {

  changePasswordForm: FormGroup;
  txtOldPassword: FormControl;
  txtNewPassword: FormControl;
  txtConfirmPassword: FormControl;

  constructor(private auth:AuthService,
    private router:Router){

    }

  ngOnInit() {

    this.txtOldPassword = new FormControl();
    this.txtNewPassword = new FormControl('',Validators.minLength(5));
    this.txtConfirmPassword = new FormControl();

    this.changePasswordForm = new FormGroup({
      txtOldPassword: this.txtOldPassword,
      txtNewPassword: this.txtNewPassword,
      txtConfirmPassword: this.txtConfirmPassword
    }, this.passwordMatchValidator);

  }

  passwordMatchValidator(g: FormGroup) {

  //return (g.get('txtNewPassword').value === g.get('txtConfirmPassword').value) ? null : {'mismatch': true}

    let result;

    if(g.get('txtNewPassword').value === g.get('txtConfirmPassword').value) {
      result = null;
    } else {
      g.get('txtConfirmPassword').setErrors({'incorrect': true});
      result = {'mismatch': true};
    }

    return result;

 }

  saveChangePassword(formValues) {

    console.log(formValues);

    if(this.changePasswordForm.valid) {

      const oldPassword = formValues.txtOldPassword;
      const newPassword = formValues.txtNewPassword;

      //send new password to the service!

    }
  }

  cancelChangePassword() {
    this.router.navigate(['home'])
  }

  getErrorMessage_txtOldPassword() {
    return "Required";
  }

  getErrorMessage_txtNewPassword() {
    return "Required";
  }

  getErrorMessage_txtConfirmPassword() {
    return "Required";
  }
}
