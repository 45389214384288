<div class="container">
  <h1>Change Password</h1>
  <hr>
  <div class="col-md-4">
    {{changePasswordForm.invalid}}
    {{txtConfirmPassword.invalid}}
  </div>
  <div class="col-md-4">

      <form #changePasswordForm="ngForm" (ngSubmit)="saveChangePassword(changePasswordForm.value)" autocomplete="off" novalidate>
        <div class="field-group">
          <div class="content-input-field">
            <input name="txtOldPassword" id="txtOldPassword" type="text" required placeholder="Email" />
          </div>
        </div>
        <div class="field-group">
          <div class="content-input-field">
            <input name="txtNewPassword" id="txtNewPassword" type="text" required placeholder="Email" />
          </div>
        </div>
        <div class="field-group">
          <div class="content-input-field">
            <input name="txtConfirmPassword" id="txtConfirmPassword" type="text" required placeholder="Email" />
          </div>
        </div>


      <div class="form-group">
        <button id="btnChange" type="submit" class="btn btn-primary">Change</button>&nbsp;&nbsp;&nbsp;
        <button type="button" class="btn btn-primary" (click)="cancelChangePassword()">Cancel</button>
      </div>
    </form>
  </div>
</div>
