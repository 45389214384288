import { LocalStorageService } from '../shared/local.storage.service';
import { User, IUser } from './auth.model';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from '../shared/base.service';



@Injectable()
export class AuthService extends BaseService {

  constructor( private http: HttpClient, private localStorage: LocalStorageService) {
    super();

    console.log('ENTROU NO AUTH SERVICE CONSTRUCTOR');

    this.currentUser = new User()

    if(this.localStorage.get("current_user_name"))
    this.currentUser.name = localStorage.get("current_user_name")
    if(this.localStorage.get("current_user_user_id"))
    this.currentUser._id = localStorage.get("current_user_id")
    if(this.localStorage.get("current_user_email"))
    this.currentUser.email = localStorage.get("current_user_email")
    if(this.localStorage.get("current_user_token"))
      this.currentUser.token = localStorage.get("current_user_token")

  }

currentUser: IUser

  loginUser(p_email:string, p_password:string) {

    let loginInfo = { email: p_email, password: p_password}
    const options = {headers: new HttpHeaders(
      {
        'Content-Type':'application/json'
      })}

    return this.http.post(environment.backend_url+'/user/authenticate',loginInfo, options)
    .pipe(
      tap(data => {

        if(data){
          if(data['token'])

            this.currentUser = <IUser>data;

            localStorage.setItem('current_user_name',this.currentUser.name);
            localStorage.setItem('current_user_user_id',this.currentUser._id);
            localStorage.setItem('current_user_email',this.currentUser.email);
            localStorage.setItem('current_user_token',this.currentUser.token);
        }
      })
    )
    .pipe(catchError(err => {
      console.log('Invalid login='+err);
      return of(false)
      }))
  }

  validate(p_email:string, p_password:string) {

    let loginInfo = { email: p_email, password: p_password}
    const options = {headers: new HttpHeaders(
      {
        'Content-Type':'application/json',
        'user-token': this.currentUser.token})
      }

    return this.http.post(environment.backend_url+'/member/validate',loginInfo, options)
    .pipe(catchError(err => {
      console.log('Wrong Password');
      console.log(err);
      return of(false)
      }))
  }


  logout(){
    localStorage.clear()
    this.currentUser = null
  }

  isAuthenticated(){
    return !!this.currentUser?._id
  }

  updateCurrentUser(formValues: any) {
    this.currentUser.name = formValues.Name
  }

  forgotPasswordRequest(p_email: string) {

    const body =
    {
      email : p_email
    };

    const options = {headers : new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  return this.http.post(environment.backend_url+'/user/forgot-password-request',body, options)
  //.pipe(catchError(this.handleError('forgotPasswordRequest')))

  }

  forgotPasswordValidateCode(p_code: string): Observable<User> {

    const options = {headers : new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  return this.http.get<User>(environment.backend_url+'/user/forgot-password/identify/'+p_code, options)
  //.pipe(catchError(this.handleError<Member>('forgotPasswordValidateCode')));

  }

  forgotPasswordChoosePassword(p_code: string, p_user_id: string, p_new_password: string) {

    const body =
    {
      _id : p_user_id,
      forgot_password_token : p_code,
      password: p_new_password
    };

    const options = {headers : new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  return this.http.post(environment.backend_url+'/user/forgot-password/confirm',body, options)
  //.pipe(catchError(this.handleError<Member>('forgotPasswordChoosePassword')))

  }

}
